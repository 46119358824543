import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Img from 'gatsby-image'
import TitleArea from '../components/TitleArea'
import { Container, Columns, Column, Content, Title, Section } from 'bloomer'

export default ({ data }) => (
  <Layout>
    <SEO title="Dog Boarding" alias="dog-boarding" />
    <TitleArea
      title="Dog Boarding"
      subtitle="Your pet’s comfort is our priority."
    />
    <Section>
      <Container className="container--narrow">
        <Columns>
          <Column>
            <Content>
              <p>We have 32 dog kennels, each with their own outdoor run.</p>
              <p />
              <p>
                Solid sides on each kennel help your pet feel secure. The
                railing on the front gates of each kennel allow guests to see
                each other.
              </p>
              <p>
                All of our kennels are heated in the winter and air conditioned
                in the summer. All kennels have ceramic floors.
              </p>
              <p>
                The soft radio playing in the background mimics the sound of
                home.
              </p>
              <p>
                Each dog has it's own individual outdoor run which measures 20
                feet long and has a pea stone base.
              </p>
              <p>
                The outdoor runs are protected from the wind by a row of spruce
                trees.
              </p>
            </Content>
            <Title
              tag="h2"
              // hasTextAlign="centered"
            >
              Pricing
            </Title>
            <Content>
              <table class="table pricing-table">
                <tbody>
                  <tr>
                    <th>1 Dog</th>
                    <td>$23 a night</td>
                  </tr>
                  <tr>
                    <th>2 Dogs</th>
                    <td>$43 a night</td>
                  </tr>
                  <tr>
                    <th>3 Dogs</th>
                    <td>$63 a night</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2">Each additional dog is $20 a night</td>
                  </tr>
                </tfoot>
              </table>
            </Content>
          </Column>

          <Column isSize="narrow">
            <div>
              <Img fixed={data.image1.childImageSharp.fixed} />
            </div>
            <Img fixed={data.image2.childImageSharp.fixed} />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Content>
              <ul>
                <li>
                  All rates are charged per night. If you brought your pet on
                  Friday, for example, and then picked it up during our Sunday
                  hours, you would be charged for 2 nights
                </li>
                <li>
                  Families <Link to="/cat-boarding/">boarding cats</Link>{' '}
                  as well as dogs have special rates. 1 cat and 1 dog are only
                  $33 per night
                </li>
                <li>Tax not included in rates</li>
              </ul>
              <h2>Boarding Agreement</h2>
              <p>
                A standard{' '}
                <Link to="/boarding-kennel-agreement/">
                  boarding kennel agreement
                </Link>{' '}
                is signed by both Shady Lane Boarding Kennel and the Pet Owner
                upon arrival.
              </p>
              <h2>Vaccinations</h2>
              <p>
                We have a{' '}
                <Link to="/vaccination-policy/">vaccination policy</Link> that
                includes bordetella and proper documentation is required at each
                visit to the kennel.
              </p>
            </Content>
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "dog-boarding/image3.jpg" }) {
      childImageSharp {
        fixed(width: 411, height: 308) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image2: file(relativePath: { eq: "dog-boarding/image2.jpg" }) {
      childImageSharp {
        fixed(width: 411, height: 308) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
